const QuoteBlock = () => {
  return (
    <>
      <section className="text-gray-600 bg-emerald-200">
        <div className="container px-5 py-5 mx-auto w-3/5">
          <div className="text-center mb-2 text-lg">
            <span className="text-base block">
              Prophet Muhammed (pbuh) said:
            </span>
            <span className="font-serif text-base leading-relaxed font-medium italic">
              "Charity brings a tenfold reward and a loan brings an eighteen
              fold reward"
            </span>
            <span className="block text-base">(Ibn Majah 2431; 15:42)</span>

            <span className="block mt-8 text-base leading-relaxed">
              Multiply your blessings eighteenfold - by giving a loan to those
              most vulnerable.
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default QuoteBlock;
