import Footer from "../components/Footer/Footer";
import TransparentHeader from "../components/TransparentHeader/TransparentHeader";
import Wallpaper from "../components/Wallpaper/Wallpaper";
// import AppStoreCTA from "../components/AppStoreCTA/AppStoreCTA";
import FeatureBlock from "../components/FeatureBlock/FeatureBlock";
import PictureSlice from "../components/PictureSlice/PictureSlice";
import { useNavigate } from "react-router-dom";
import QuoteBlock from "../components/QuoteBlock/QuoteBlock";

const Home = () => {
  let navigate = useNavigate();

  const pictureSliceHandler = () => {
    navigate("/apply", { replace: false });
  };

  return (
    <>
      <TransparentHeader />
      <Wallpaper />
      <FeatureBlock />
      <QuoteBlock />
      <PictureSlice
        slice="point"
        title="Get control of your finances"
        subtitle="We're here to help you improve your credit rating and get your finances in order."
        withButton
        buttonText="Learn more"
        buttonHandler={pictureSliceHandler}
      />
      {/* <AppStoreCTA /> */}
      <Footer />
    </>
  );
};

export default Home;
